import { Component, Inject, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Swal from "sweetalert2";
import DfStore from "@/vue/domain/store/df-store";

@Component({
  components: {
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
  },
})
export default class DfFormPasswordForgottenComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private email = "";
  private formError: any = {};
  private working = false;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  private recoveryPassword() {
    this.formError = {};
    this.working = true;
    this.service
      .recoveryPassword({ email: this.email, storeIdentifier: this.currentStore.identifier })
      .then((response: any) => {
        this.working = false;
        Swal.fire({
          title: this.$t("df-form-forgotten-password.modal.recovery-password.title").toString(),
          text: this.$t("df-form-forgotten-password.modal.recovery-password.body").toString(),
          icon: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("df-form-forgotten-password.modal.recovery-password.ok").toString(),
        }).then((result) => {
          if (result.value) {
            this.$emit("recoveredPassword");
          }
        });
      })
      .catch((error: any) => {
        this.working = false;
        if (error.response.data.errors) {
          error.response.data.errors.forEach((errorData: any) => {
            this.formError[errorData.field] = `${errorData.objectName}-${errorData.code}`;
          });
        } else {
          this.formError["email"] = "df-form-password-forgotten.errors.generic";
        }
      });
  }
}